import React, { Component } from 'react';

interface Props {
    progress: number;
}

export class ProgressBar extends Component<Props> {
    render() {
        const adStyle = {
            display: 'inline-block',
            width: '336px',
            height: '280px',
        };
        const progress = (100 * this.props.progress).toFixed(2);
        return (
            <div>
                <p>Transcoding: {progress}% complete...</p>
            </div>
        );
    }
}
