import React, { Component } from 'react';

export class Footer extends Component {
    render() {
        var emailriddlerarray = [
            115, 117, 112, 112, 111, 114, 116, 64, 109, 111, 118, 105, 101, 114,
            111, 116, 97, 116, 111, 114, 46, 99, 111, 109,
        ];
        var encryptedemail_id31 = '';
        for (var i = 0; i < emailriddlerarray.length; i++) {
            encryptedemail_id31 += String.fromCharCode(emailriddlerarray[i]);
        }

        return (
            <div id="footer">
                <div id="link-bar">
                    <a href="http://MovieRotator.com">Movie Rotator</a> | |
                    <a href={`mailto:${encryptedemail_id31}`}>
                        {encryptedemail_id31}
                    </a>{' '}
                    | Copyright (C) 2013 Chris Pearce
                </div>
            </div>
        );
    }
}
