import React, { Component } from 'react';

interface Props {
    blob: ArrayBufferLike;
    reset: () => void;
}

export class DownloadComplete extends Component<Props> {
    render() {
        return (
            <div>
                <button
                    onClick={() => {
                        if (this.props.blob) {
                            downloadBlob(this.props.blob, 'rotated.mp4');
                        }
                    }}
                >
                    Save rotated video to computer
                </button>
                <button
                    onClick={() => {
                        this.props.reset();
                    }}
                >
                    Start Over
                </button>
            </div>
        );
    }
}

function downloadBlob(body: ArrayBufferLike, filename: string) {
    const blob = new Blob([body]);
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        const link = document.createElement('a');
        // Browsers that support HTML5 download attribute
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
