import React, { Component } from 'react';
import './App.css';
import { Rotator } from './Rotator';
import { Header } from './Header';
import { Footer } from './Footer';

class App extends Component {
    render() {
        return (
            <div id="main">
                <Header />
                <Rotator />
                <Footer />
            </div>
        );
    }
}

export default App;
