import React, { Component, RefObject } from 'react';

interface Props {
    rotation: number;
    rotate: () => void;
    transcode: () => void;
    reset: () => void;
    objectURL: string;
}

interface State {
    playing: boolean;
}

export class Player extends Component<Props> {
    state: Readonly<State> = {
        playing: false,
    };
    video: RefObject<HTMLVideoElement>;
    constructor(props: Props) {
        super(props);
        this.video = React.createRef<HTMLVideoElement>();
    }
    render() {
        const videoStyle = {
            transform: `rotate(${this.props.rotation * 90}deg)`,
            transition: 'transform 0.8s ease-in-out',
        };
        const togglePlay = () => {
            if (this.video.current) {
                if (this.video.current.paused) {
                    this.video.current.play();
                } else {
                    this.video.current.pause();
                }
            }
        };
        return (
            <div id="player">
                <div id="video-container">
                    <video
                        ref={this.video}
                        id="video"
                        src={this.props.objectURL}
                        style={videoStyle}
                        muted
                        onPlaying={() => {
                            this.setState({ playing: true });
                        }}
                        onPause={() => {
                            this.setState({ playing: false });
                        }}
                    ></video>
                </div>
                <button onClick={togglePlay}>
                    {this.state.playing ? 'Pause' : 'Play'}
                </button>
                <button onClick={this.props.rotate}>Rotate</button>
                <button onClick={this.props.transcode}>Save</button>
                <button onClick={this.props.reset}>Start Over</button>
            </div>
        );
    }
}
