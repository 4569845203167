import React, { Component } from 'react';
import { createFFmpeg, fetchFile, FFmpeg } from '@ffmpeg/ffmpeg';
import './App.css';
import { FileChooser } from './FileChooser';
import { Player } from './Player';
import { DownloadComplete } from './DownloadComplete';
import { ProgressBar } from './ProgressBar';

interface Props {}

interface State {
    file: File | null;
    objectURL: string;
    rotation: number;
    progress: number | null;
    rotated: ArrayBufferLike | null;
}

export class Rotator extends Component<Props, State> {
    state: Readonly<State> = {
        file: null,
        objectURL: '',
        rotation: 0,
        progress: null,
        rotated: null,
    };
    ffmpeg: FFmpeg;

    constructor(props: Props) {
        super(props);
        const progress = (params: { ratio: number }) => {
            console.log(`Progress ${params.ratio}`);
            this.setState({ progress: params.ratio });
        };
        this.ffmpeg = createFFmpeg({ log: true, progress });
        this.ffmpeg.load();
    }

    render() {
        let component;
        if (this.state.file == null) {
            component = (
                <FileChooser
                    chosen={(file: File) => {
                        this.setState({
                            file: file,
                            objectURL: window.URL.createObjectURL(file),
                        });
                    }}
                />
            );
        } else if (this.state.progress == null) {
            component = (
                <Player
                    rotation={this.state.rotation}
                    rotate={() => {
                        const rotation = this.state.rotation + 1;
                        this.setState({ rotation: rotation });
                    }}
                    transcode={() => {
                        this.transcode(this.state.file, this.state.rotation);
                    }}
                    reset={() => {
                        this.reset();
                    }}
                    objectURL={this.state.objectURL}
                />
            );
        } else if (this.state.rotated != null) {
            component = (
                <DownloadComplete
                    blob={this.state.rotated}
                    reset={() => this.reset()}
                />
            );
        } else {
            // Else, progress != null. We have a transcode in progress.
            component = <ProgressBar progress={this.state.progress} />;
        }

        return <div id="rotator">{component}</div>;
    }

    reset() {
        this.setState({
            file: null,
            objectURL: '',
            rotation: 0,
            progress: null,
            rotated: null,
        });
    }

    async transcode(file: File | null, rotation: number) {
        if (!file) {
            return;
        }

        this.ffmpeg.FS('writeFile', file.name, await fetchFile(file));

        this.setState({ progress: 0 });

        let audio: string[] = [];
        if (file.name.endsWith('.mp4') || file.name.endsWith('.mov')) {
            audio = ['-c:a', 'copy'];
        }

        await this.ffmpeg.run(
            '-i',
            file.name,
            ...audio,
            ...rotationStrings(rotation),
            'output.mp4'
        );
        const data = this.ffmpeg.FS('readFile', 'output.mp4');
        this.setState({ rotated: data.buffer });
        this.ffmpeg.FS('unlink', 'output.mp4');
    }
}

function rotationStrings(rotation: number): string[] {
    const rotations = [
        [],
        ['-vf', 'transpose=1'],
        ['-vf', 'transpose=2,transpose=2'],
        ['-vf', 'transpose=2'],
    ];
    return rotations[Math.abs(rotation) % 4];
}
