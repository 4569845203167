import React, { Component } from 'react';

export class Header extends Component {
    render() {
        return (
            <div>
                <div id="logo">
                    <h1>MOVIE ROTATOR</h1>
                </div>
            </div>
        );
    }
}
