import React, { Component } from 'react';

interface Props {
    chosen: (f: File) => void;
}

export class FileChooser extends Component<Props> {
    render() {
        const adStyle = {
            display: 'inline-block',
            width: '336px',
            height: '280px',
        };
        return (
            <div>
                <h1>Quickly rotate digital camera videos</h1>
                <p>
                    Rotate videos online right in your browser using
                    MovieRotator.com.
                </p>
                <p>
                    If you have digital movies which are in a{' '}
                    <b>landscape orientation</b>, and you want them in{' '}
                    <b>portrait orientation</b>, MovieRotator can rotate them so
                    that you can view them easily.
                </p>
                <p>
                    There's <b>no software to download</b> and install, and your
                    videos <i>never leave your computer</i>. Your videos are
                    rotated in your browser, without your personal files being
                    uploaded somewhere else.
                </p>

                <p>Movie Rotator is provided free of charge.</p>

                <p>
                    <b>To rotate a video, select a file from your computer:</b>
                </p>
                <p>
                    <input
                        type="file"
                        id="select-file"
                        onChange={(event) => {
                            if (!event.currentTarget.files) {
                                return;
                            }
                            const file = event.currentTarget.files[0];
                            this.props.chosen(file);
                            this.setState({
                                file: file,
                                objectURL: window.URL.createObjectURL(file),
                            });
                        }}
                    />
                </p>
            </div>
        );
    }
}
